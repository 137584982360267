@import '../../../../../../../styles/shared';

$select-background-color: #f2f2f2;

.wrapper {
  position: relative;
  user-select: none;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 48px;
  padding: 0 24px;
  font-family: var(--hub-product-module-top-filter-dropdown-font-family);
  background: $select-background-color;
  border-radius: 24px;
  cursor: pointer;

  &:hover {
    background-color: darken($select-background-color, 3%);
  }
}

.label {
  position: relative;
  top: var(--hub-button-text-top-offset);
  font-family: var(--hub-product-module-top-filter-dropdown-label-font-family);
  font-size: 14px;
  color: var(--hub-product-module-color);
  white-space: nowrap;

  @include mq($from: desktop) {
    font-size: 18px;
  }
}

.arrowIcon {
  color: var(--hub-product-module-color);

  &::before {
    display: inline-block;
    width: 24px;
  }
}

.expandMoreRoundedIcon {
  &::before {
    content: 'expand_more_rounded';
  }
}

.expandLessRoundedIcon {
  &::before {
    content: 'expand_less_rounded';
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  max-height: 0;
  background-color: $select-background-color;
  border-radius: 0 0 24px 24px;
  overflow: hidden;
  z-index: 100;
}

.scroller {
  height: 314px;
  padding: 10px 0 0;
  overflow-x: hidden;
}

.option {
  padding: 10px 24px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;

  &:hover {
    background-color: darken($select-background-color, 3%);
  }

  &.selected {
    display: none;
  }
}

.resetButton {
  font-weight: bold;
}

.bottomArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  .arrowIcon {
    color: #6a6f71;
  }
}

.opened {
  .select {
    border-radius: 24px 24px 0 0;

    &::after {
      position: absolute;
      right: 24px;
      left: 24px;
      bottom: 0;
      content: '';
      border-bottom: 1px solid #d9d9d9;
    }

    &:hover {
      background-color: $select-background-color;
    }
  }

  .dropdown {
    max-height: fit-content;
  }
}
